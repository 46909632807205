<template>
	<main id="container" class="page-container information bg-wave3">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="행사정보" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title">
						<span class="prima">살맛나는 행복쇼핑 동행축제에</span><br />
						여러분을 초대합니다.
					</h3>
				</div>
				<div class="information-header">
					<p class="info-header-text">9월 동행축제는 추석 명절을 맞이하여 다양한 지역행사와 함께 판촉전으로 지역경제를 활성화하고, <br />중소ㆍ소상공인 글로벌화를 위한 해외 특별행사를 개최할 예정입니다.</p>
				</div>
				<div class="information-content">
					<section class="information-content-section flex">
						<div class="information-content-section__header">
							<h4 class="info-content-title">동행축제 소개</h4>
							<p class="info-content-text">내수 활성화를 위해 전국적 대규모 소비축제인 대한민국 동행축제를 개최하여 소비부담 완화 및 중소ㆍ소상공인 매출 증대</p>
						</div>
						<div class="info-content-img">
							<img src="@/assets/img/information/info-img01.jpg" alt="" />
						</div>
					</section>
					<section class="information-content-section">
						<h4 class="info-content-title">행사내용</h4>
						<article class="information-content-article">
							<h5 class="information-content-article__title"><strong>부처협업</strong> : 농ㆍ축ㆍ수산물 할인 판촉, 여행ㆍ관광 촉진, 유통ㆍ제조업계 할인행사 등 동행축제 연계 소비행사</h5>
							<ul class="information-content-article__list">
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img11.jpg" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img12.jpg" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img13.jpg" alt="" />
									</div>
								</li>
							</ul>
						</article>
						<article class="information-content-article">
							<h5 class="information-content-article__title"><strong>지역연계</strong> : 지역축제·행사와 연계한 판촉행사, 전통시장‧상점가 (1,812곳), 백년가게, 착한 가격업소, 향토기업 등 제품할인·홍보</h5>
							<ul class="information-content-article__list">
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img21.jpg" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img22.jpg" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img23.jpg" alt="" />
									</div>
								</li>
							</ul>
						</article>
						<article class="information-content-article">
							<h5 class="information-content-article__title"><strong>민간협업</strong> : 마트ㆍ백화점 등 유통사 판매전, 국내 온라인 쇼핑몰, O2O 플랫폼, 홈쇼핑ㆍT커머스 등을 통한 할인행사 참여</h5>
							<!-- <ul class="information-content-article__list">
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img31.png" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img32.png" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img33.png" alt="" />
									</div>
								</li>
							</ul> -->
						</article>
						<article class="information-content-article">
							<h5 class="information-content-article__title"><strong>해외연계</strong> : 글로벌 유통플랫폼과 협업, 동남아 대상 동행축제 할인전 및 할인행사 등 중소ㆍ소상공인 수출길 개척을 위한 동행축제 글로벌 행사 개최</h5>
							<!-- <ul class="information-content-article__list">
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img41.png" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img42.png" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img43.png" alt="" />
									</div>
								</li>
							</ul> -->
						</article>
					</section>
				</div>
				<div class="information-period">
					<div class="period-desc">
						<span class="period-desc-title">행사기간</span>
						<span class="period-desc-date">2024년 8월 28일(수) ~ 9월 28일(토)</span>
					</div>
					<div class="period-icon">
						<img src="@/assets/img/information/info-calendar-icon.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
export default {
	name: 'Information',
	components: { PageHeader },
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
